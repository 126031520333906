import {
  FIRST_HAIRCARE_QUESTION_ROUTE,
  FIRST_SKINCARE_QUESTION_ROUTE,
} from 'Apps/Consultation/constants';

import { HAIRCARE_CART_URL, SKINCARE_CART_URL } from 'constants/cart';

import imageSC from 'assets/images/promotions/prose-custom-skincare-products-stacked.jpg';
import image60Percent from 'assets/images/promotions/trial-offer-claw-clipper-gwp-promotion-hc-sc-sss-modal-60.jpg';

const content = {
  contentDefault: {
    banner: 'Spring Welcome Offer: 60% off + free gift',
    popUpModal: {
      image: image60Percent,
      headerTitle: 'SPRING WELCOME OFFER',
      title: (
        <>
          Get 60% off your first order
          <br /> + a free Midi Claw Clip
        </>
      ),
      description:
        'Subscribe to any 3+ haircare products to claim your free gift. Limited time only!',
      cta: {
        consultationCompleted: {
          linkTo: () => HAIRCARE_CART_URL,
          dataClick: 'order-your-prose',
          dataFrom: 'hc-sc-pop-up',
          ctaLabel: 'unlock offer',
        },
        consultationNotCompleted: {
          linkTo: (route: string) => route || FIRST_HAIRCARE_QUESTION_ROUTE,
          dataClick: 'get-your-formula',
          dataFrom: 'hc-sc-pop-up',
          ctaLabel: 'unlock offer',
        },
      },
      ctaExit: {
        dataClick: 'exit',
        dataFrom: 'hc-sc-pop-up',
      },
      conditions: (
        <>
          *Get [1] free Midi Claw Clip when you subscribe to 3+ haircare products. Enjoy a total of
          60% off your first subscription order when you subscribe to haircare. Subsequent
          subscription orders will receive free shipping and 15% off. Offer is valid when your
          subscription order is placed by 04/16/2025 at 11:59 PM EST or while supplies last. Cannot
          be applied to previous orders or non-subscribed items. Not valid for accessories or
          digital gift cards. For full details on our return policy, click{' '}
          <a
            data-click="return-policy"
            data-from="skincare-minis-hc-trial-popup"
            href="https://prose.com/faq/5f3da05bceb96c001a595c02"
            target="_blank"
          >
            here
          </a>
          .
        </>
      ),
      tracking: {
        modalClosing: 'Trial Offer v2 HC + SC Pop-up - Closed',
        modalOpening: 'Trial Offer v2 HC + SC Pop-up - Viewed',
        modalOpening2sec: 'Trial Offer v2 HC + SC Pop-up - Viewed (2 seconds)',
      },
    },
    bottomModal: {
      title: 'SPRING WELCOME OFFER',
      description: (
        <>
          Try Prose with <b>60% off + a free Midi Claw Clip</b>, when you subscribe to any 3+
          haircare products.
        </>
      ),
      conditions: (
        <>
          *Get [1] free Midi Claw Clip when you subscribe to 3+ haircare products. Enjoy a total of
          60% off your first subscription order when you subscribe to haircare. Subsequent
          subscription orders will receive free shipping and 15% off. Offer is valid when your
          subscription order is placed by 04/16/2025 at 11:59 PM EST or while supplies last. Cannot
          be applied to previous orders or non-subscribed items. Not valid for accessories or
          digital gift cards. For full details on our return policy, click{' '}
          <a
            data-click="return-policy"
            data-from="skincare-minis-hc-trial-popup"
            href="https://prose.com/faq/5f3da05bceb96c001a595c02"
            target="_blank"
          >
            here
          </a>
          .
        </>
      ),
    },
    checkoutCommunication: {
      title: 'SPRING WELCOME OFFER',
      description: (
        <>
          <b>Get 60% off + a free Midi Claw Clip</b> when you subscribe to any 3+ haircare products.
        </>
      ),
      terms: 'See terms & conditions',
    },
  },
  contentHaircare: {
    emailCaptureModal: {
      title: '60% off your first order + free claw clip',
      description: 'Subscribe to any 3+ haircare products to unlock your exclusive welcome offer.',
    },
    banner: 'Spring Welcome Offer: 60% off + free gift',
    popUpModal: {
      image: image60Percent,
      headerTitle: 'SPRING WELCOME OFFER',
      title: (
        <>
          Get 60% off your first order
          <br /> + a free Midi Claw Clip
        </>
      ),
      description:
        'Subscribe to any 3+ haircare products to claim your free gift. Limited time only!',
      cta: {
        consultationCompleted: {
          linkTo: () => HAIRCARE_CART_URL,
          dataClick: 'order-your-prose',
          dataFrom: 'hc-pop-up',
          ctaLabel: 'unlock offer',
        },
        consultationNotCompleted: {
          linkTo: (route: string) => route || FIRST_HAIRCARE_QUESTION_ROUTE,
          dataClick: 'get-your-formula',
          dataFrom: 'hc-pop-up',
          ctaLabel: 'unlock offer',
        },
      },
      ctaExit: {
        dataClick: 'exit',
        dataFrom: 'hc-pop-up',
      },
      conditions: (
        <>
          *Get [1] free Midi Claw Clip when you subscribe to 3+ haircare products. Enjoy a total of
          60% off your first subscription order when you subscribe to haircare. Subsequent
          subscription orders will receive free shipping and 15% off. Offer is valid when your
          subscription order is placed by 04/16/2025 at 11:59 PM EST or while supplies last. Cannot
          be applied to previous orders or non-subscribed items. Not valid for accessories or
          digital gift cards. For full details on our return policy, click{' '}
          <a
            data-click="return-policy"
            data-from="skincare-minis-hc-trial-popup"
            href="https://prose.com/faq/5f3da05bceb96c001a595c02"
            target="_blank"
          >
            here
          </a>
          .
        </>
      ),
      tracking: {
        modalClosing: 'Trial Offer v2 HC Pop-up - Closed',
        modalOpening: 'Trial Offer v2 HC Pop-up - Viewed',
        modalOpening2sec: 'Trial Offer v2 HC Pop-up - Viewed (2 seconds)',
      },
    },
    bottomModal: {
      title: 'SPRING WELCOME OFFER',
      description: (
        <>
          Try Prose with <b>60% off + a free Midi Claw Clip</b>, when you subscribe to any 3+
          haircare products.
        </>
      ),
      conditions: (
        <>
          *Get [1] free Midi Claw Clip when you subscribe to 3+ haircare products. Enjoy a total of
          60% off your first subscription order when you subscribe to haircare. Subsequent
          subscription orders will receive free shipping and 15% off. Offer is valid when your
          subscription order is placed by 04/16/2025 at 11:59 PM EST or while supplies last. Cannot
          be applied to previous orders or non-subscribed items. Not valid for accessories or
          digital gift cards. For full details on our return policy, click{' '}
          <a
            data-click="return-policy"
            data-from="skincare-minis-hc-trial-popup"
            href="https://prose.com/faq/5f3da05bceb96c001a595c02"
            target="_blank"
          >
            here
          </a>
          .
        </>
      ),
    },
    checkoutCommunication: {
      title: 'SPRING WELCOME OFFER',
      description: (
        <>
          <b>Get 60% off + a free Midi Claw Clip</b> when you subscribe to any 3+ haircare products.
        </>
      ),
      terms: 'See terms & conditions',
    },
  },
  contentSkincare: {
    banner: 'Subscribe for 50% off skincare',
    popUpModal: {
      image: imageSC,
      imageAlt: 'Prose custom skincare products',
      headerTitle: 'FIND YOUR FORMULA',
      title: 'Discover the custom difference',
      description: (
        <>
          500k 5-star product reviews*
          <br />
          Made with natural, clean, safe ingredients
          <br />
          Love your first order (or it’s on us)
        </>
      ),
      cta: {
        consultationCompleted: {
          linkTo: () => SKINCARE_CART_URL,
          dataClick: 'order-your-prose',
          dataFrom: 'sc-pop-up',
          ctaLabel: 'Try prose for 50% off',
        },
        consultationNotCompleted: {
          linkTo: () => FIRST_SKINCARE_QUESTION_ROUTE,
          dataClick: 'get-your-formula',
          dataFrom: 'sc-pop-up',
          ctaLabel: 'Try prose for 50% OFF',
        },
      },
      ctaExit: {
        dataClick: 'exit',
        dataFrom: 'sc-pop-up',
      },
      conditions: (
        <>
          *on Review & Refine®
          <br />
          50% off your first subscription order to skincare. Subsequent subscription orders will
          receive free shipping and 15% off. Cannot be applied to previous orders. Not valid for
          accessories or digital gift cards. Offer subject to expire.
        </>
      ),
      tracking: {
        modalClosing: 'Trial Offer v2 SC Pop-up - Closed',
        modalOpening: 'Trial Offer v2 SC Pop-up - Viewed',
        modalOpening2sec: 'Trial Offer v2 SC Pop-up - Viewed (2 seconds)',
      },
    },
    bottomModal: {
      title: 'FIND YOUR FORMULA',
      description: (
        <>
          Get <b>50% off</b> skincare <b>+ free shipping</b>
          <br />
          when you subscribe.
        </>
      ),
      conditions:
        '50% off your first subscription order to skincare. Subsequent subscription orders will receive free shipping and 15% off. Cannot be applied to previous orders. Not valid for accessories or digital gift cards. Offer subject to expire.',
    },
    checkoutCommunication: {
      title: 'FIND YOUR FORMULA',
      description: (
        <>
          Get <b>50% off</b> skincare <b>+ free shipping</b>
          <br />
          when you subscribe
        </>
      ),
      terms: 'See terms & conditions',
    },
  },
};

export default content;
